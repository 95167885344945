/* HTML: <div class="loader"></div> */
.loader {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 30px;
    color: white;
    animation: l1 1s linear infinite alternate;
  }
  .loader:before {
    content:"𝔸𝕄..."
  }
  @keyframes l1 {to{opacity: 0}}