.active{
    text-decoration: underline !important;
    text-decoration-color: #AF3B3F !important;
    text-decoration-thickness: 3px !important;
    text-underline-offset: 8px !important;

   }
   


  
 