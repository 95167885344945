.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: black !important;
  font-size: 30px !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: black !important;
  font-size: 30px !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  font-size: 30px !important;
}


